import React from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import { getRouteUrl } from "us.helper";
import { DeleteOutlined } from "us.icons";
import { MatchHelper } from "us.collection.economy/components/ManagePayments/Functions";
import { ICaseTable } from "us.collection.economy/components/ManagePayments/interface";
import { managePayment } from "us.collection.economy/actions";

import ParentRow from "./ParentRow";
import { IRootState } from "us.collection/interfaces";
import { connect } from "react-redux";
import { Align } from "us.collection.economy/constants";

const { deleteCaseTableRowKey, addCaseTableRowKey } = managePayment;

const { $AmountLabel, $InputAmount, $Table, $Tag, $Button } = Components;

const CaseTable: React.FC<ICaseTable> = ({
  data,
  exceededBalance,
  isLoading,
  currentCurrency,
  currentLanguage,
  onChangeMappingBalance,
  onDeleteCase,
  activeRowKeys,
  addCaseTableRowKey,
  deleteCaseTableRowKey,
}) => {
  const { t } = useTranslation();

  const onExpandRow = (expanded: boolean, record: any) => {
    if (expanded) {
      addCaseTableRowKey(record.key);
    } else {
      deleteCaseTableRowKey(record.key);
    }
  };

  const renderCell = (text: any, record: any, dataIndex: string) => {
    const {
      isParent,
      deletable,
      caseType,
      caseId,
      caseNo,
      caseStatus,
      isObjected,
      arId,
      creditorName,
      debtorName,
      balance,
      arIndex,
      childIndex,
      mappingBalance,
    } = record;
    const row = {
      children: (
        <>
          {dataIndex === "delete" && !isParent && deletable && (
            <$Button
              icon={<DeleteOutlined />}
              size="small"
              type="primary"
              danger
              className={"mb-3"}
              onClick={() => onDeleteCase(record)}
              id="btn-delete-case"
            />
          )}
          {!isParent && dataIndex === "caseNo" && (
            <div className="d-flex align-items-center">
              <a
                style={{ width: 55 }}
                onClick={() => getRouteUrl.caseType(caseNo)}
              >
                {caseNo}
              </a>
              <$Tag
                className={`ml-2 ${MatchHelper.getCaseTag(caseStatus).class}`}
                data-testid="case-status-tag"
              >
                {t(MatchHelper.getCaseTag(caseStatus).name)}
              </$Tag>
              {isObjected && (
                <$Tag className={`ml-2 `} data-testid="case-objected-tag">
                  {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.OBJECTED")}
                </$Tag>
              )}
            </div>
          )}
          {isParent && dataIndex === "caseNo" && (
            <ParentRow
              arId={arId}
              creditorName={creditorName}
              debtorName={debtorName}
            />
          )}
          {!isParent && dataIndex === "balance" && (
            <$AmountLabel value={balance} data-testid={"case-amount"} />
          )}
          {!isParent && dataIndex === "mappingBalance" && (
            <$InputAmount
              name={`caseTableData[${arIndex}].children[${childIndex}].mappingBalance`}
              min={0}
              value={mappingBalance > 0 ? mappingBalance : 0}
              className="bui-number-input"
              placeholder="0,00"
              currentLanguage={currentLanguage}
              currentCurrency={currentCurrency}
              defaultValue={mappingBalance > 0 ? mappingBalance : 0}
              onChange={(value) => {
                onChangeMappingBalance({
                  record,
                  value,
                });
              }}
              disabled={
                (exceededBalance == 0 &&
                  (text == "" || typeof text === "undefined")) ||
                balance === 0
              }
              data-testid={"input-mapping-balance"}
            />
          )}
        </>
      ),
      props: {
        colSpan:
          isParent && dataIndex !== "delete"
            ? dataIndex === "caseNo"
              ? 3
              : 0
            : undefined,
      },
    };
    return row;
  };

  // Case Table Columns setup
  const columns = [
    {
      title: "",
      key: "delete",
      dataIndex: "delete",
      width: "50px",
      render: (text: any, record: any) => renderCell(text, record, "delete"),
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASE_NO"),
      dataIndex: "caseNo",
      key: "caseNo",
      render: (text: any, record: any) => renderCell(text, record, "caseNo"),
      sorter: (a: any, b: any) => a.caseNo - b.caseNo,
    },
    {
      title: t("US.COLLECTION.COMMON:COMMON.BALANCE"),
      dataIndex: "balance",
      key: "balance",
      align: Align.RIGHT,
      render: (text: any, record: any) => renderCell(text, record, "balance"),
      sorter: (a: any, b: any) => a.balance - b.balance,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPING_BALANCE"),
      dataIndex: "mappingBalance",
      key: "mappingBalance",
      width: "200px",
      align: Align.RIGHT,
      render: (text: any, record: any) =>
        renderCell(text, record, "mappingBalance"),
    },
  ];
  return (
    <$Table
      columns={columns}
      rowKey={(record) => record.key}
      dataSource={data}
      className="table-striped header-custom-tag"
      bordered
      size="small"
      loading={isLoading}
      pagination={{ defaultPageSize: 20 }}
      expandable={{
        defaultExpandedRowKeys: activeRowKeys,
        expandedRowKeys: activeRowKeys,
        onExpand: onExpandRow,
      }}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { managePayment } = state;

  const { matchPaymentData } = managePayment;
  return {
    activeRowKeys: matchPaymentData.activeRowKeys,
  };
};

const mapDispatchToProps = { addCaseTableRowKey, deleteCaseTableRowKey };

export default connect(mapStateToProps, mapDispatchToProps)(CaseTable);
